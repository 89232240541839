export class PortReader {
    private remainder: Uint8Array = new Uint8Array(0);

    constructor(private reader: ReadableStreamDefaultReader) {
    }

    async read(bytes: number, timeoutMs: number): Promise<Uint8Array | undefined> {
        let timeoutId: NodeJS.Timeout | undefined;
        const timeoutPromise = new Promise<undefined>((_, reject) => {
            timeoutId = setTimeout(
                () => reject(new Error(`Timeout after ${timeoutMs}ms`)),
                timeoutMs
            );
        });

        const readPromise = (async () => {
            try {
                // Need to read some bytes in
                while (this.remainder.length < bytes) {
                    const { value, done } = await this.reader.read();

                    if (done) {
                        // stream closed, done now.
                        return undefined;
                    }

                    // console.log(`PortReader chunk ${value}`);

                    const valueArr = value as Uint8Array;

                    const newArr = new Uint8Array(this.remainder.length + valueArr.length);
                    newArr.set(this.remainder, 0);
                    newArr.set(valueArr, this.remainder.length);
                    this.remainder = newArr;
                }

                // Everything we need is already read in
                const result = this.remainder.slice(0, bytes);
                this.remainder = this.remainder.slice(bytes, this.remainder.length);
                return result;
            } finally {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
            }
        })();

        return Promise.race([readPromise, timeoutPromise]);
    }
}
