import { useId, useState } from "react";
import * as cmd from "./Commands";
import { SetTestMode } from "./generated/wss_pb";

type Props = Readonly<{
    port: SerialPort;
    onDisconnect: (why: any) => void;
}>;

export const TestMode = ({ port, onDisconnect }: Props): JSX.Element => {
    const [vssTestSpeed, setVssTestSpeed] = useState(0);
    const vssTestId = useId();

    const onSetTestMode = async () => {
        const testModeParams = new SetTestMode();
        testModeParams.setKph(vssTestSpeed);

        await cmd.setTestMode(port, onDisconnect, testModeParams);
    };

    const onExitTestMode = async() => {
        setVssTestSpeed(0);

        await cmd.exitTestMode(port, onDisconnect);
    };

    return (
        <>
            <h2>Test Mode</h2>
            <label htmlFor={vssTestId}>Test vehicle speed output (kph):</label>
            <input type='number' id={vssTestId} value={vssTestSpeed} onChange={v => setVssTestSpeed(v.target.valueAsNumber)} />

            <p />
            <button type='button' onClick={onSetTestMode}>Set Test Mode</button>
            <button type='button' onClick={onExitTestMode}>Exit Test Mode</button>
        </>
    )
};
