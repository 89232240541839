import { MutableRefObject, useEffect, useState } from "react";
import * as cmd from './Commands';
import { AbsType, Status } from "./generated/wss_pb";

type Props = Readonly<{
    port: SerialPort;
    onDisconnect: (why: any) => void;
    absTypeRef: MutableRefObject<AbsType>;
    isMfgMode: boolean;
}>;

export const StatusView = ({ port, onDisconnect, absTypeRef, isMfgMode }: Props): JSX.Element => {
    const [status, setStatus] = useState<Status>();

    useEffect(() => {
        let done = false;

        (async () => {
            while (!done) {
                const onDisconnectInternal = (e: any) => {
                    onDisconnect(e);
                    return;
                };

                const resp = await cmd.getStatus(port, onDisconnectInternal);
                setStatus(resp?.getStatus());
                await new Promise(f => setTimeout(f, 100));
            }
        })();

        return () => { done = true; };
    }, [port, onDisconnect]);

    // TODO: can MK60 send fault light state too?
    const absType = absTypeRef.current;
    const hasWheelSpeeds = (absType === AbsType.MK60 || absType === AbsType.MK60E1 || absType === AbsType.MK60E5);
    const hasFaultLight = (absType === AbsType.MK60E1 || absType === AbsType.MK60E5);
    const hasInputPressureSensor = (absType === AbsType.MK60E1 || absType === AbsType.MK60E5);
    const hasWheelPressureSensors = absType === AbsType.MK60E5;

    const selfTest = status?.getSelftest();
    const selfTestResult = selfTest ?
            ("LF: " + (selfTest.getAnaloglfok() ? "PASS" : "FAIL") + "\n" +
             "RF: " + (selfTest.getAnalogrfok() ? "PASS" : "FAIL") + "\n" +
             "LR: " + (selfTest.getAnaloglrok() ? "PASS" : "FAIL") + "\n" +
             "RR: " + (selfTest.getAnalogrrok() ? "PASS" : "FAIL"))
            : "Not run";

    return (
        <> {status && (<>
            <h2>Status</h2>

            <h3>Internal sensors</h3>
            <div>Brake switch input: {status?.getBrakeswitchinput() ? 'pressed' : 'not pressed'}</div>
            <div>ABS brake switch input: {status?.getAbsbrakeswitchstate() ? 'pressed' : 'not pressed'}</div>
            { false && (<div>Internal temp: {status?.getInternaltemperature()} C</div>)}
            <div>CAN receive count: {status?.getCanrxcounter()}</div>
            <div>CAN transmit OK count: {status?.getCantxokcounter()}</div>
            <div>CAN transmit ERR count: {status?.getCantxerrcounter()}</div>
            {hasFaultLight && (<div>ABS fault light on: {status?.getFaultlighton() ? 'yes' : 'no'}</div>)}

            <h3>Controller sensors</h3>
            {hasWheelSpeeds && (<><h4>Wheel Speeds</h4>
            <div>Vehicle speed: {status?.getVehiclespeed()}</div>
            <CornersView corners={[status.getWsslf(), status.getWssrf(), status.getWsslr(), status.getWssrr()]} /></>)}
            {(hasInputPressureSensor || hasWheelPressureSensors) && (<h4>Brake Pressure</h4>)}
            {hasInputPressureSensor && (<div>Input pressure: {status?.getBrakepressinput()}</div>)}
            {hasWheelPressureSensors && (
                <CornersView corners={[status.getBrakepresslf(), status.getBrakepressrf(), status.getBrakepresslr(), status.getBrakepressrr()]} />
            )}
            {isMfgMode && (<>
                <div>Self test:</div>
                <div>{selfTestResult}</div>
            </>)}
        </>)} </>
    );
};

const CornersView = ({ corners }: Readonly<{ corners: number[] }>): JSX.Element => {
    return (<>
        <table><tbody>
            <tr>
                <th />
                <th>Left</th>
                <th>Right</th>
            </tr>
            <tr>
                <th>Front</th>
                <td>{corners[0]}</td>
                <td>{corners[1]}</td>
            </tr>
            <tr>
                <th>Rear</th>
                <td>{corners[2]}</td>
                <td>{corners[3]}</td>
            </tr>
        </tbody></table>
    </>);
};
