import { MutableRefObject, useCallback, useEffect, useId, useState } from "react";
import * as cmd from "./Commands";
import { AbsType, WssConfig } from "./generated/wss_pb";

type DirPickerProps = Readonly<{
    text: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}>;

const DirPicker = ({ text, checked, onChange }: DirPickerProps): JSX.Element => {
    const id = useId();
    return (<div>
        <label htmlFor={id}>{text}</label>
        <input type='checkbox' checked={checked} onChange={v => onChange(v.target.checked)} />
    </div>);
}

type ConfiguratorProps = Readonly<{
    config: WssConfig;
    onWriteConfig: (config: WssConfig) => void;
    onSelfTest: () => void;
    isMfgMode: boolean;
    onSetAbsType: (type: AbsType) => void;
}>;

const Configurator = ({ config, onWriteConfig, onSelfTest, isMfgMode, onSetAbsType }: ConfiguratorProps) => {
    const [absType, setAbsType] = useState(config.getType());
    const absTypeId = useId();

    useEffect(() => {
        onSetAbsType(absType);
    }, [onSetAbsType, absType]);

    const [speedoPulsePerKm, setSpeedoPulsePerKm] = useState(config.getSpeedopulseperkm());
    const speedoPulsePerKmId = useId();

    const [dirLf, setDirLf] = useState(config.getDirlf());
    const [dirRf, setDirRf] = useState(config.getDirrf());
    const [dirLr, setDirLr] = useState(config.getDirlr());
    const [dirRr, setDirRr] = useState(config.getDirrr());

    const [invertFaultOutput, setInvertFaultOutput] = useState(config.getInvertfaultoutput());
    const [flashWhenAbsActive, setFlashWhenAbsActive] = useState(config.getFlashwhenabsactive());
    const [divideByTwo, setDivideByTwo] = useState(config.getDividebytwo());
    const [invertBrakeInput, setInvertBrakeInput] = useState(config.getInvertbrakeinput());

    const [canTransmitExt, setCanTransmitExt] = useState(config.getCantransmitext());
    const canExtId = useId();
    const [canTransmitId, setCanTransmitId] = useState(config.getCantransmitid());

    useEffect(() => {
        config.setType(absType);
        config.setSpeedopulseperkm(speedoPulsePerKm);
        config.setDirlf(dirLf);
        config.setDirrf(dirRf);
        config.setDirlr(dirLr);
        config.setDirrr(dirRr);
        config.setInvertfaultoutput(invertFaultOutput);
        config.setFlashwhenabsactive(flashWhenAbsActive);
        config.setDividebytwo(divideByTwo);
        config.setInvertbrakeinput(invertBrakeInput);
    }, [config, absType, speedoPulsePerKm, dirLf, dirRf, dirLr, dirRr, invertFaultOutput, flashWhenAbsActive, canTransmitExt, canTransmitId, divideByTwo, invertBrakeInput]);

    const hasDirectionOutput = absType === AbsType.MK60E5;
    const hasVehicleSpeed = (absType === AbsType.MK60) || (absType === AbsType.MK60E1) || (absType === AbsType.MK60E5);
    const hasFaultLight = (absType === AbsType.MK60E1) || (absType === AbsType.MK60E5);

    return (
        <>
            <label htmlFor={absTypeId}>ABS controller type:</label>
            <select id={absTypeId} value={absType} onChange={c => setAbsType(+c.target.value)}>
                <option value={AbsType.MK60}>Mk60</option>
                <option value={AbsType.MK60E1}>Mk60e1</option>
                <option value={AbsType.MK60E5}>Mk60e5</option>
                <option value={AbsType.GENERICONOFF}>Generic On-Off Hall</option>
            </select>
            <p />

            {hasVehicleSpeed &&
                (<>
                    <label htmlFor={speedoPulsePerKmId}>Speedometer output pulse/km:</label>
                    <input type='number' id={speedoPulsePerKmId} value={speedoPulsePerKm} onChange={v => setSpeedoPulsePerKm(v.target.valueAsNumber)} />
                    <p />
                </>)}

            {hasDirectionOutput &&
                (<>
                    <h3>Wheel directions</h3>
                    <DirPicker text='Left front' checked={dirLf} onChange={setDirLf} />
                    <DirPicker text='Right front' checked={dirRf} onChange={setDirRf} />
                    <DirPicker text='Left rear' checked={dirLr} onChange={setDirLr} />
                    <DirPicker text='Right rear' checked={dirRr} onChange={setDirRr} />
                    <p />
                </>)}

            {hasFaultLight && (<>
                <DirPicker text='Invert fault light output' checked={invertFaultOutput} onChange={setInvertFaultOutput} />
                <DirPicker text='Flash fault light when ABS/DSC active' checked={flashWhenAbsActive} onChange={setFlashWhenAbsActive} />
                <p />
            </>)}

            <DirPicker text='Invert brake switch' checked={invertBrakeInput} onChange={setInvertBrakeInput} />
            <p />

            <DirPicker text='Divide speed by 2 (96 tooth wheels)' checked={divideByTwo} onChange={setDivideByTwo} />

            <DirPicker text='Can transmit extended' checked={canTransmitExt} onChange={setCanTransmitExt} />
            <label htmlFor={canExtId}>CAN transmit ID:</label>
            <input type='number' id={canExtId} value={canTransmitId} onChange={v => setCanTransmitId(v.target.valueAsNumber)} />

            <p />
            <button type='button' onClick={() => onWriteConfig(config)}>Write config</button>

            {isMfgMode && (<>
                <p />
                <button type='button' onClick={onSelfTest}>Run self test</button>
            </>)}
        </>);
};

type Props = Readonly<{
    port: SerialPort;
    onDisconnect: (why: any) => void;
    absTypeRef: MutableRefObject<AbsType>;
    isMfgMode: boolean;
}>;

export const Settings = ({ port, onDisconnect, absTypeRef, isMfgMode }: Props): JSX.Element => {
    const [config, setConfig] = useState<WssConfig | undefined>(undefined);

    // Read config from controller upon first render
    useEffect(() => {
        (async () => {
            const result = await cmd.getConfig(port, onDisconnect);

            if (result?.hasConfiguration()) {
                setConfig(result.getConfiguration());
            }
        })();
    }, [port, onDisconnect]);

    const onWriteConfig = useCallback(async (cfg: WssConfig) => {
        if (cfg) {
            await cmd.setConfig(port, onDisconnect, cfg);
        }
    }, [port, onDisconnect]);

    const onSelfTest = async () => {
        await cmd.runSelfTest(port, onDisconnect);
    };

    return (<>
        <h2>Configuration</h2>
        {config && (<><Configurator config={config} onWriteConfig={onWriteConfig} onSelfTest={onSelfTest} isMfgMode onSetAbsType={(type) => absTypeRef.current = type} /> <p /></>)}
    </>);
};
