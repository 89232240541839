import { PortReader } from "./SerialUtils";
import { Request, Response, WssConfig } from "./generated/wss_pb";
import { Mutex } from "async-mutex";

class PortDisconnectedError extends Error {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, PortDisconnectedError.prototype);
    }
}

const writeRequest = async (port: SerialPort, o: Request, commandId: number) => {
    o.setCmdid(commandId);

    const bin = o.serializeBinary();

    const fullBuffer = new Uint8Array(bin.length + 1);
    fullBuffer[0] = bin.length - 1;
    fullBuffer.set(bin, 1);

    //console.log(`writeRequest cmdId ${commandId} fullBuffer ${fullBuffer}`);

    //const start = Date.now();

    //await new Promise(f => setTimeout(f, 10));

    const writer = port.writable.getWriter();
    await writer.write(fullBuffer);
    //await new Promise(f => setTimeout(f, 10));
    writer.releaseLock();

    //const duration = Date.now() - start;
    //console.log(`write duration ${duration}`);
};

const readResponse = async (port: SerialPort, timeoutMs: number): Promise<Response | undefined> => {
    const readable = port.readable;

    if (!readable) {
        throw new PortDisconnectedError('Serial port readable was null, port disconnected?');
    }

    const reader = readable.getReader();

    try {
        const pr = new PortReader(reader);

        // Read length byte
        const lengthByte = await pr.read(1, timeoutMs);

        // console.log(`read lengthByte ${lengthByte}`);

        if (!lengthByte) {
            return undefined;
        }

        const dataBytes = await pr.read(lengthByte[0] + 1, 1000);

        // console.log(`read dataBytes ${dataBytes}`);

        if (!dataBytes) {
            return undefined;
        }

        const resp = Response.deserializeBinary(dataBytes);

        console.log(`Got response: id: ${resp.getCmdid()} type: ${describeResponse(resp)}`);

        return resp;
    } catch {
        return undefined;
    } finally {
        reader.releaseLock();
    }
};

let nextCmdId = 100;

const protobufTransactImpl = async (port: SerialPort, request: Request, timeoutMs: number = 5000): Promise<Response | undefined> => {
    const start = Date.now();

    const cmdId = nextCmdId++;

    await writeRequest(port, request, cmdId);
    const result = await readResponse(port, timeoutMs);

    const duration = Date.now() - start;
    console.log(`duration ${duration}`);

    // Check that the received command ID matches expected
    if (result && result.getCmdid() !== cmdId) {
        throw new Error(`Transaction ID mismatch expected ${cmdId} but got ${result.getCmdid()} for command ${describeRequest(request)} and got back ${describeResponse(result)}`);
    }

    return result;
};

const mutex = new Mutex();
export const protobufTransact = async (port: SerialPort, request: Request, onDisconnect: (why: any) => void, timeoutMs: number = 5000): Promise<Response | undefined> => {
    try {
        // await here to observe error
        return await mutex.runExclusive(() => protobufTransactImpl(port, request, timeoutMs));
    } catch (e) {
        console.error(`protobufTransact failed with error ${e}`);

        if (e instanceof PortDisconnectedError) {
            onDisconnect(e);
        } else {
            throw e;
        }
    }
}

export const isAck = (response?: Response): boolean => {
    return !!(response && response.hasAck() && !response.hasNak());
};

export const describeConfiguration = (config: WssConfig): string => {
    return `WssConfig type ${config.getType().toString()}`
        + ` wheel dirs [ ${config.getDirlf()} ${config.getDirrf()} ${config.getDirlr()} ${config.getDirrr()} ] speedo pulse/km ${config.getSpeedopulseperkm()}`;
};

export const describeRequest = (request: Request): string => {
    switch (true) {
        case request.hasErase():
            return 'Erase flash';
        case request.hasWrite():
            return 'Write flash';
        case request.hasGetconfig():
            return 'Get config';
        case request.hasGetstatus():
            return 'Get status';
        case request.hasIdentify():
            return 'Identify';
        case request.hasSetconfig():
            return 'Set config';
        case request.hasSettestmode():
            return 'Set test mode';
        default:
            return 'Unknown';
    }
};

export const describeResponse = (response: Response): string => {
    switch (true) {
        case response.hasAck():
            return 'Ack';
        case response.hasNak():
            return 'Nak';
        case response.hasIdent():
            const ident = response.getIdent()!;
            return `Ident: isBootloader ${ident.getIsbootloader()} version ${ident.getVersion()} hardware revision ${ident.getHardwarerevision()} base addr 0x${ident.getAppstartaddress().toString(16)} app size 0x${ident.getAppsize().toString(16)}`;
        case response.hasConfiguration():
            return describeConfiguration(response.getConfiguration()!);
        case response.hasStatus():
            return `Status response`;
        default:
            return 'Unknown';
    }
};
