import { useEffect, useState } from "react";
import { isAck } from "./ProtobufCommand";

import * as cmd from './Commands';

import { Buffer } from "buffer";
import { IdentResponse } from "./generated/wss_pb";
window.Buffer = Buffer;

type Props = Readonly<{
    port: SerialPort;
    firmwareLocation: string;
    controllerIdent: IdentResponse;
    onDisconnect: (why: any) => void;
}>;

const doErase = async (port: SerialPort, onDisconnect: (why: any) => void, baseAddress: number, size: number, updateProgress: (pct: number) => void): Promise<boolean> => {
    const chunkSize = 4096;

    let erased = 0;

    for (let addr = baseAddress; addr < baseAddress + size; addr += chunkSize) {
        const resp = await cmd.erase(port, onDisconnect, addr, chunkSize);

        if (!isAck(resp)) {
            return false;
        }

        erased += chunkSize;
        updateProgress(erased / size);
    }

    return true;
}

export const Updater = ({ port, firmwareLocation, controllerIdent, onDisconnect }: Props): JSX.Element => {
    const [firmwareImage, setFirmwareImage] = useState<Uint8Array | undefined>();

    const baseAddress = controllerIdent.getAppstartaddress();
    const firmwareMaxSize = controllerIdent.getAppsize();

    // Only allow ESP passthrough if using USB (not bluetooth) and on new enough HW for it
    const hasEsp32Passthrough = controllerIdent.getHardwarerevision() >= 102 && !controllerIdent.getIsbluetooth();

    // Load the firmware image
    useEffect(() => {
        (async () => {
            const hexFile = await fetch(firmwareLocation).then((response) => response.text());
            if (hexFile) {
                setFirmwareImage(require('intel-hex').parse(hexFile, hexFile.length, baseAddress).data);
            } else {
                setFirmwareImage(undefined);
            }
        })();
    }, [firmwareLocation, baseAddress]);

    const [progress, setProgress] = useState(0);
    const [updateMessage, setUpdateMessage] = useState('Not started');

    const onStartUpdate = async () => {
        if (firmwareImage) {
            setUpdateMessage('Erasing...');
            if (!await doErase(port, onDisconnect, baseAddress, firmwareMaxSize, (p) => setProgress(50 * p))) {
                console.error('erase failed');
                setUpdateMessage('Erase FAILED');
                return;
            }

            let offset = 0;
            let remain = firmwareImage.length;

            // TODO: why does 256 not work?
            const maxChunk = 32;

            setUpdateMessage('Writing...');

            while (remain > 0) {
                const chunkSize = remain > maxChunk ? maxChunk : remain;

                console.info(`Write chunk at addr ${baseAddress + offset} chunkSize ${chunkSize} remain ${remain}`);

                const respWrite = await cmd.write(port, onDisconnect, baseAddress + offset, firmwareImage.slice(offset, offset + chunkSize));

                if (!isAck(respWrite)) {
                    console.error(`Write FAILED at chunk addr ${baseAddress + offset} chunkSize ${chunkSize} remain ${remain}`);
                    setUpdateMessage('Write FAILED');
                    return;
                }

                offset += chunkSize;
                remain -= chunkSize;

                setProgress(50 + 50 * (offset / firmwareImage.length));
            }

            setUpdateMessage('Done!');
        }
    };

    const doEspPassthrough = async () => {
        await cmd.doEspPassthrough(port, onDisconnect);
        onDisconnect(false);
    };

    return (<>
        <h2>Firmware Update</h2>
        <div>Firmware image size: {firmwareImage && firmwareImage.length}</div>
        <div>Progress: <progress max="100" value={progress} /></div>
        <div>Status: {updateMessage}</div>
        <div className="warning">Warning: Updating firmware will reset your configuration to defaults.</div>
        {firmwareImage && (<button type='button' onClick={onStartUpdate}>Start firmware update</button>)}
        {hasEsp32Passthrough && (<button type='button' onClick={doEspPassthrough}>ESP FW update mode</button>)}
    </>);
}