// source: wss.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.AbsType', null, global);
goog.exportSymbol('proto.Ack', null, global);
goog.exportSymbol('proto.Command', null, global);
goog.exportSymbol('proto.DataWithAddr', null, global);
goog.exportSymbol('proto.IdentResponse', null, global);
goog.exportSymbol('proto.Nak', null, global);
goog.exportSymbol('proto.OffsetSize', null, global);
goog.exportSymbol('proto.Request', null, global);
goog.exportSymbol('proto.Request.ReqCase', null, global);
goog.exportSymbol('proto.Response', null, global);
goog.exportSymbol('proto.Response.RespCase', null, global);
goog.exportSymbol('proto.SelfTestResponse', null, global);
goog.exportSymbol('proto.SetTestMode', null, global);
goog.exportSymbol('proto.Status', null, global);
goog.exportSymbol('proto.WssConfig', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OffsetSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OffsetSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OffsetSize.displayName = 'proto.OffsetSize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DataWithAddr = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DataWithAddr, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DataWithAddr.displayName = 'proto.DataWithAddr';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.WssConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.WssConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.WssConfig.displayName = 'proto.WssConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Command = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Command, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Command.displayName = 'proto.Command';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetTestMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SetTestMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetTestMode.displayName = 'proto.SetTestMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Request.oneofGroups_);
};
goog.inherits(proto.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Request.displayName = 'proto.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Ack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Ack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Ack.displayName = 'proto.Ack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Nak = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Nak, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Nak.displayName = 'proto.Nak';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IdentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IdentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IdentResponse.displayName = 'proto.IdentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SelfTestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SelfTestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SelfTestResponse.displayName = 'proto.SelfTestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Status.displayName = 'proto.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Response.oneofGroups_);
};
goog.inherits(proto.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Response.displayName = 'proto.Response';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OffsetSize.prototype.toObject = function(opt_includeInstance) {
  return proto.OffsetSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OffsetSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OffsetSize.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: jspb.Message.getFieldWithDefault(msg, 1, 0),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OffsetSize}
 */
proto.OffsetSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OffsetSize;
  return proto.OffsetSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OffsetSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OffsetSize}
 */
proto.OffsetSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setOffset(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OffsetSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OffsetSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OffsetSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OffsetSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffset();
  if (f !== 0) {
    writer.writeFixed32(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeFixed32(
      2,
      f
    );
  }
};


/**
 * optional fixed32 Offset = 1;
 * @return {number}
 */
proto.OffsetSize.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.OffsetSize} returns this
 */
proto.OffsetSize.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional fixed32 Size = 2;
 * @return {number}
 */
proto.OffsetSize.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.OffsetSize} returns this
 */
proto.OffsetSize.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DataWithAddr.prototype.toObject = function(opt_includeInstance) {
  return proto.DataWithAddr.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DataWithAddr} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataWithAddr.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DataWithAddr}
 */
proto.DataWithAddr.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DataWithAddr;
  return proto.DataWithAddr.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DataWithAddr} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DataWithAddr}
 */
proto.DataWithAddr.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setOffset(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DataWithAddr.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DataWithAddr.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DataWithAddr} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataWithAddr.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffset();
  if (f !== 0) {
    writer.writeFixed32(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional fixed32 Offset = 1;
 * @return {number}
 */
proto.DataWithAddr.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DataWithAddr} returns this
 */
proto.DataWithAddr.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes Data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.DataWithAddr.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes Data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.DataWithAddr.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes Data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.DataWithAddr.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.DataWithAddr} returns this
 */
proto.DataWithAddr.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.WssConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.WssConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.WssConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WssConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dirlf: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    dirrf: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    dirlr: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    dirrr: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    speedopulseperkm: jspb.Message.getFieldWithDefault(msg, 6, 0),
    invertfaultoutput: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    flashwhenabsactive: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    cantransmitext: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    cantransmitid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    dividebytwo: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    invertbrakeinput: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.WssConfig}
 */
proto.WssConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.WssConfig;
  return proto.WssConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.WssConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.WssConfig}
 */
proto.WssConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.AbsType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirlf(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirrf(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirlr(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirrr(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSpeedopulseperkm(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvertfaultoutput(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFlashwhenabsactive(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCantransmitext(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCantransmitid(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDividebytwo(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvertbrakeinput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.WssConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.WssConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.WssConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WssConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirlf();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDirrf();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDirlr();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDirrr();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSpeedopulseperkm();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getInvertfaultoutput();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFlashwhenabsactive();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCantransmitext();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCantransmitid();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getDividebytwo();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getInvertbrakeinput();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional AbsType type = 1;
 * @return {!proto.AbsType}
 */
proto.WssConfig.prototype.getType = function() {
  return /** @type {!proto.AbsType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.AbsType} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool dirLf = 2;
 * @return {boolean}
 */
proto.WssConfig.prototype.getDirlf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setDirlf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool dirRf = 3;
 * @return {boolean}
 */
proto.WssConfig.prototype.getDirrf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setDirrf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool dirLr = 4;
 * @return {boolean}
 */
proto.WssConfig.prototype.getDirlr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setDirlr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool dirRr = 5;
 * @return {boolean}
 */
proto.WssConfig.prototype.getDirrr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setDirrr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional uint32 speedoPulsePerKm = 6;
 * @return {number}
 */
proto.WssConfig.prototype.getSpeedopulseperkm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setSpeedopulseperkm = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool invertFaultOutput = 7;
 * @return {boolean}
 */
proto.WssConfig.prototype.getInvertfaultoutput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setInvertfaultoutput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool flashWhenAbsActive = 8;
 * @return {boolean}
 */
proto.WssConfig.prototype.getFlashwhenabsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setFlashwhenabsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool canTransmitExt = 9;
 * @return {boolean}
 */
proto.WssConfig.prototype.getCantransmitext = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setCantransmitext = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional uint32 canTransmitId = 10;
 * @return {number}
 */
proto.WssConfig.prototype.getCantransmitid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setCantransmitid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool divideByTwo = 11;
 * @return {boolean}
 */
proto.WssConfig.prototype.getDividebytwo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setDividebytwo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool invertBrakeInput = 12;
 * @return {boolean}
 */
proto.WssConfig.prototype.getInvertbrakeinput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WssConfig} returns this
 */
proto.WssConfig.prototype.setInvertbrakeinput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Command.prototype.toObject = function(opt_includeInstance) {
  return proto.Command.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Command} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Command.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Command}
 */
proto.Command.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Command;
  return proto.Command.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Command} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Command}
 */
proto.Command.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Command.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Command.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Command} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Command.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetTestMode.prototype.toObject = function(opt_includeInstance) {
  return proto.SetTestMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetTestMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetTestMode.toObject = function(includeInstance, msg) {
  var f, obj = {
    kph: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetTestMode}
 */
proto.SetTestMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetTestMode;
  return proto.SetTestMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetTestMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetTestMode}
 */
proto.SetTestMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setKph(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetTestMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetTestMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetTestMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetTestMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKph();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 kph = 1;
 * @return {number}
 */
proto.SetTestMode.prototype.getKph = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SetTestMode} returns this
 */
proto.SetTestMode.prototype.setKph = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Request.oneofGroups_ = [[10,11,12,13,14,15,16,17,18,19,20]];

/**
 * @enum {number}
 */
proto.Request.ReqCase = {
  REQ_NOT_SET: 0,
  ERASE: 10,
  WRITE: 11,
  IDENTIFY: 12,
  REBOOTLOADER: 13,
  GETCONFIG: 14,
  SETCONFIG: 15,
  GETSTATUS: 16,
  SETTESTMODE: 17,
  EXITTESTMODE: 18,
  RUNSELFTEST: 19,
  DOESPPASSTHROUGH: 20
};

/**
 * @return {proto.Request.ReqCase}
 */
proto.Request.prototype.getReqCase = function() {
  return /** @type {proto.Request.ReqCase} */(jspb.Message.computeOneofCase(this, proto.Request.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    cmdid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    erase: (f = msg.getErase()) && proto.OffsetSize.toObject(includeInstance, f),
    write: (f = msg.getWrite()) && proto.DataWithAddr.toObject(includeInstance, f),
    identify: (f = msg.getIdentify()) && proto.Command.toObject(includeInstance, f),
    rebootloader: (f = msg.getRebootloader()) && proto.Command.toObject(includeInstance, f),
    getconfig: (f = msg.getGetconfig()) && proto.Command.toObject(includeInstance, f),
    setconfig: (f = msg.getSetconfig()) && proto.WssConfig.toObject(includeInstance, f),
    getstatus: (f = msg.getGetstatus()) && proto.Command.toObject(includeInstance, f),
    settestmode: (f = msg.getSettestmode()) && proto.SetTestMode.toObject(includeInstance, f),
    exittestmode: (f = msg.getExittestmode()) && proto.Command.toObject(includeInstance, f),
    runselftest: (f = msg.getRunselftest()) && proto.Command.toObject(includeInstance, f),
    doesppassthrough: (f = msg.getDoesppassthrough()) && proto.Command.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Request}
 */
proto.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Request;
  return proto.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Request}
 */
proto.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCmdid(value);
      break;
    case 10:
      var value = new proto.OffsetSize;
      reader.readMessage(value,proto.OffsetSize.deserializeBinaryFromReader);
      msg.setErase(value);
      break;
    case 11:
      var value = new proto.DataWithAddr;
      reader.readMessage(value,proto.DataWithAddr.deserializeBinaryFromReader);
      msg.setWrite(value);
      break;
    case 12:
      var value = new proto.Command;
      reader.readMessage(value,proto.Command.deserializeBinaryFromReader);
      msg.setIdentify(value);
      break;
    case 13:
      var value = new proto.Command;
      reader.readMessage(value,proto.Command.deserializeBinaryFromReader);
      msg.setRebootloader(value);
      break;
    case 14:
      var value = new proto.Command;
      reader.readMessage(value,proto.Command.deserializeBinaryFromReader);
      msg.setGetconfig(value);
      break;
    case 15:
      var value = new proto.WssConfig;
      reader.readMessage(value,proto.WssConfig.deserializeBinaryFromReader);
      msg.setSetconfig(value);
      break;
    case 16:
      var value = new proto.Command;
      reader.readMessage(value,proto.Command.deserializeBinaryFromReader);
      msg.setGetstatus(value);
      break;
    case 17:
      var value = new proto.SetTestMode;
      reader.readMessage(value,proto.SetTestMode.deserializeBinaryFromReader);
      msg.setSettestmode(value);
      break;
    case 18:
      var value = new proto.Command;
      reader.readMessage(value,proto.Command.deserializeBinaryFromReader);
      msg.setExittestmode(value);
      break;
    case 19:
      var value = new proto.Command;
      reader.readMessage(value,proto.Command.deserializeBinaryFromReader);
      msg.setRunselftest(value);
      break;
    case 20:
      var value = new proto.Command;
      reader.readMessage(value,proto.Command.deserializeBinaryFromReader);
      msg.setDoesppassthrough(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCmdid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getErase();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.OffsetSize.serializeBinaryToWriter
    );
  }
  f = message.getWrite();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.DataWithAddr.serializeBinaryToWriter
    );
  }
  f = message.getIdentify();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.Command.serializeBinaryToWriter
    );
  }
  f = message.getRebootloader();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.Command.serializeBinaryToWriter
    );
  }
  f = message.getGetconfig();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.Command.serializeBinaryToWriter
    );
  }
  f = message.getSetconfig();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.WssConfig.serializeBinaryToWriter
    );
  }
  f = message.getGetstatus();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.Command.serializeBinaryToWriter
    );
  }
  f = message.getSettestmode();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.SetTestMode.serializeBinaryToWriter
    );
  }
  f = message.getExittestmode();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.Command.serializeBinaryToWriter
    );
  }
  f = message.getRunselftest();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.Command.serializeBinaryToWriter
    );
  }
  f = message.getDoesppassthrough();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.Command.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 CmdId = 1;
 * @return {number}
 */
proto.Request.prototype.getCmdid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setCmdid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional OffsetSize erase = 10;
 * @return {?proto.OffsetSize}
 */
proto.Request.prototype.getErase = function() {
  return /** @type{?proto.OffsetSize} */ (
    jspb.Message.getWrapperField(this, proto.OffsetSize, 10));
};


/**
 * @param {?proto.OffsetSize|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setErase = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearErase = function() {
  return this.setErase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasErase = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DataWithAddr write = 11;
 * @return {?proto.DataWithAddr}
 */
proto.Request.prototype.getWrite = function() {
  return /** @type{?proto.DataWithAddr} */ (
    jspb.Message.getWrapperField(this, proto.DataWithAddr, 11));
};


/**
 * @param {?proto.DataWithAddr|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setWrite = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearWrite = function() {
  return this.setWrite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasWrite = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Command identify = 12;
 * @return {?proto.Command}
 */
proto.Request.prototype.getIdentify = function() {
  return /** @type{?proto.Command} */ (
    jspb.Message.getWrapperField(this, proto.Command, 12));
};


/**
 * @param {?proto.Command|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setIdentify = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearIdentify = function() {
  return this.setIdentify(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasIdentify = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Command rebootLoader = 13;
 * @return {?proto.Command}
 */
proto.Request.prototype.getRebootloader = function() {
  return /** @type{?proto.Command} */ (
    jspb.Message.getWrapperField(this, proto.Command, 13));
};


/**
 * @param {?proto.Command|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setRebootloader = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearRebootloader = function() {
  return this.setRebootloader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasRebootloader = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Command getConfig = 14;
 * @return {?proto.Command}
 */
proto.Request.prototype.getGetconfig = function() {
  return /** @type{?proto.Command} */ (
    jspb.Message.getWrapperField(this, proto.Command, 14));
};


/**
 * @param {?proto.Command|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setGetconfig = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearGetconfig = function() {
  return this.setGetconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasGetconfig = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional WssConfig setConfig = 15;
 * @return {?proto.WssConfig}
 */
proto.Request.prototype.getSetconfig = function() {
  return /** @type{?proto.WssConfig} */ (
    jspb.Message.getWrapperField(this, proto.WssConfig, 15));
};


/**
 * @param {?proto.WssConfig|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setSetconfig = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearSetconfig = function() {
  return this.setSetconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasSetconfig = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Command getStatus = 16;
 * @return {?proto.Command}
 */
proto.Request.prototype.getGetstatus = function() {
  return /** @type{?proto.Command} */ (
    jspb.Message.getWrapperField(this, proto.Command, 16));
};


/**
 * @param {?proto.Command|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setGetstatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearGetstatus = function() {
  return this.setGetstatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasGetstatus = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional SetTestMode setTestMode = 17;
 * @return {?proto.SetTestMode}
 */
proto.Request.prototype.getSettestmode = function() {
  return /** @type{?proto.SetTestMode} */ (
    jspb.Message.getWrapperField(this, proto.SetTestMode, 17));
};


/**
 * @param {?proto.SetTestMode|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setSettestmode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearSettestmode = function() {
  return this.setSettestmode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasSettestmode = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Command exitTestMode = 18;
 * @return {?proto.Command}
 */
proto.Request.prototype.getExittestmode = function() {
  return /** @type{?proto.Command} */ (
    jspb.Message.getWrapperField(this, proto.Command, 18));
};


/**
 * @param {?proto.Command|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setExittestmode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearExittestmode = function() {
  return this.setExittestmode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasExittestmode = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Command runSelfTest = 19;
 * @return {?proto.Command}
 */
proto.Request.prototype.getRunselftest = function() {
  return /** @type{?proto.Command} */ (
    jspb.Message.getWrapperField(this, proto.Command, 19));
};


/**
 * @param {?proto.Command|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setRunselftest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearRunselftest = function() {
  return this.setRunselftest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasRunselftest = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Command doEspPassthrough = 20;
 * @return {?proto.Command}
 */
proto.Request.prototype.getDoesppassthrough = function() {
  return /** @type{?proto.Command} */ (
    jspb.Message.getWrapperField(this, proto.Command, 20));
};


/**
 * @param {?proto.Command|undefined} value
 * @return {!proto.Request} returns this
*/
proto.Request.prototype.setDoesppassthrough = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearDoesppassthrough = function() {
  return this.setDoesppassthrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasDoesppassthrough = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Ack.prototype.toObject = function(opt_includeInstance) {
  return proto.Ack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Ack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Ack.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Ack}
 */
proto.Ack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Ack;
  return proto.Ack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Ack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Ack}
 */
proto.Ack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Ack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Ack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Ack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Ack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Nak.prototype.toObject = function(opt_includeInstance) {
  return proto.Nak.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Nak} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Nak.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Nak}
 */
proto.Nak.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Nak;
  return proto.Nak.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Nak} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Nak}
 */
proto.Nak.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Nak.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Nak.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Nak} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Nak.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IdentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.IdentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IdentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IdentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isbootloader: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hardwarerevision: jspb.Message.getFieldWithDefault(msg, 3, 0),
    appstartaddress: jspb.Message.getFieldWithDefault(msg, 4, 0),
    appsize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    serialnumber: msg.getSerialnumber_asB64(),
    isbluetooth: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IdentResponse}
 */
proto.IdentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IdentResponse;
  return proto.IdentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IdentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IdentResponse}
 */
proto.IdentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsbootloader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHardwarerevision(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAppstartaddress(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAppsize(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSerialnumber(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsbluetooth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IdentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IdentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IdentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IdentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsbootloader();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getHardwarerevision();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAppstartaddress();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAppsize();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getSerialnumber_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getIsbluetooth();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional bool isBootloader = 1;
 * @return {boolean}
 */
proto.IdentResponse.prototype.getIsbootloader = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.IdentResponse} returns this
 */
proto.IdentResponse.prototype.setIsbootloader = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 version = 2;
 * @return {number}
 */
proto.IdentResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.IdentResponse} returns this
 */
proto.IdentResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 hardwareRevision = 3;
 * @return {number}
 */
proto.IdentResponse.prototype.getHardwarerevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.IdentResponse} returns this
 */
proto.IdentResponse.prototype.setHardwarerevision = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 appStartAddress = 4;
 * @return {number}
 */
proto.IdentResponse.prototype.getAppstartaddress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.IdentResponse} returns this
 */
proto.IdentResponse.prototype.setAppstartaddress = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 appSize = 5;
 * @return {number}
 */
proto.IdentResponse.prototype.getAppsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.IdentResponse} returns this
 */
proto.IdentResponse.prototype.setAppsize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bytes serialNumber = 6;
 * @return {!(string|Uint8Array)}
 */
proto.IdentResponse.prototype.getSerialnumber = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes serialNumber = 6;
 * This is a type-conversion wrapper around `getSerialnumber()`
 * @return {string}
 */
proto.IdentResponse.prototype.getSerialnumber_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSerialnumber()));
};


/**
 * optional bytes serialNumber = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSerialnumber()`
 * @return {!Uint8Array}
 */
proto.IdentResponse.prototype.getSerialnumber_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSerialnumber()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.IdentResponse} returns this
 */
proto.IdentResponse.prototype.setSerialnumber = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional bool isBluetooth = 7;
 * @return {boolean}
 */
proto.IdentResponse.prototype.getIsbluetooth = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.IdentResponse} returns this
 */
proto.IdentResponse.prototype.setIsbluetooth = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SelfTestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SelfTestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SelfTestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SelfTestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    analoglfok: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    analogrfok: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    analoglrok: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    analogrrok: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SelfTestResponse}
 */
proto.SelfTestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SelfTestResponse;
  return proto.SelfTestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SelfTestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SelfTestResponse}
 */
proto.SelfTestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnaloglfok(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnalogrfok(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnaloglrok(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnalogrrok(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SelfTestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SelfTestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SelfTestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SelfTestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnaloglfok();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAnalogrfok();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAnaloglrok();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAnalogrrok();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool analogLfOk = 1;
 * @return {boolean}
 */
proto.SelfTestResponse.prototype.getAnaloglfok = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SelfTestResponse} returns this
 */
proto.SelfTestResponse.prototype.setAnaloglfok = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool analogRfOk = 2;
 * @return {boolean}
 */
proto.SelfTestResponse.prototype.getAnalogrfok = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SelfTestResponse} returns this
 */
proto.SelfTestResponse.prototype.setAnalogrfok = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool analogLrOk = 3;
 * @return {boolean}
 */
proto.SelfTestResponse.prototype.getAnaloglrok = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SelfTestResponse} returns this
 */
proto.SelfTestResponse.prototype.setAnaloglrok = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool analogRrOk = 4;
 * @return {boolean}
 */
proto.SelfTestResponse.prototype.getAnalogrrok = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SelfTestResponse} returns this
 */
proto.SelfTestResponse.prototype.setAnalogrrok = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    wsslf: jspb.Message.getFieldWithDefault(msg, 1, 0),
    wssrf: jspb.Message.getFieldWithDefault(msg, 2, 0),
    wsslr: jspb.Message.getFieldWithDefault(msg, 3, 0),
    wssrr: jspb.Message.getFieldWithDefault(msg, 4, 0),
    brakepresslf: jspb.Message.getFieldWithDefault(msg, 5, 0),
    brakepressrf: jspb.Message.getFieldWithDefault(msg, 6, 0),
    brakepresslr: jspb.Message.getFieldWithDefault(msg, 7, 0),
    brakepressrr: jspb.Message.getFieldWithDefault(msg, 8, 0),
    internaltemperature: jspb.Message.getFieldWithDefault(msg, 9, 0),
    brakeswitchinput: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    canrxcounter: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cantxokcounter: jspb.Message.getFieldWithDefault(msg, 12, 0),
    cantxerrcounter: jspb.Message.getFieldWithDefault(msg, 13, 0),
    vehiclespeed: jspb.Message.getFieldWithDefault(msg, 14, 0),
    faultlighton: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    absactive: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    absbrakeswitchstate: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    brakepressinput: jspb.Message.getFieldWithDefault(msg, 18, 0),
    selftest: (f = msg.getSelftest()) && proto.SelfTestResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Status}
 */
proto.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Status;
  return proto.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Status}
 */
proto.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWsslf(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWssrf(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWsslr(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWssrr(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBrakepresslf(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBrakepressrf(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBrakepresslr(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBrakepressrr(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInternaltemperature(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBrakeswitchinput(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCanrxcounter(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCantxokcounter(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCantxerrcounter(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehiclespeed(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFaultlighton(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAbsactive(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAbsbrakeswitchstate(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBrakepressinput(value);
      break;
    case 19:
      var value = new proto.SelfTestResponse;
      reader.readMessage(value,proto.SelfTestResponse.deserializeBinaryFromReader);
      msg.setSelftest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWsslf();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWssrf();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getWsslr();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getWssrr();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBrakepresslf();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getBrakepressrf();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getBrakepresslr();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBrakepressrr();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getInternaltemperature();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBrakeswitchinput();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCanrxcounter();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getCantxokcounter();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getCantxerrcounter();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getVehiclespeed();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getFaultlighton();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getAbsactive();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getAbsbrakeswitchstate();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getBrakepressinput();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getSelftest();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.SelfTestResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 wssLf = 1;
 * @return {number}
 */
proto.Status.prototype.getWsslf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setWsslf = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 wssRf = 2;
 * @return {number}
 */
proto.Status.prototype.getWssrf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setWssrf = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 wssLr = 3;
 * @return {number}
 */
proto.Status.prototype.getWsslr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setWsslr = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 wssRr = 4;
 * @return {number}
 */
proto.Status.prototype.getWssrr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setWssrr = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 brakePressLf = 5;
 * @return {number}
 */
proto.Status.prototype.getBrakepresslf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setBrakepresslf = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 brakePressRf = 6;
 * @return {number}
 */
proto.Status.prototype.getBrakepressrf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setBrakepressrf = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 brakePressLr = 7;
 * @return {number}
 */
proto.Status.prototype.getBrakepresslr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setBrakepresslr = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 brakePressRr = 8;
 * @return {number}
 */
proto.Status.prototype.getBrakepressrr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setBrakepressrr = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 internalTemperature = 9;
 * @return {number}
 */
proto.Status.prototype.getInternaltemperature = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setInternaltemperature = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool brakeSwitchInput = 10;
 * @return {boolean}
 */
proto.Status.prototype.getBrakeswitchinput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setBrakeswitchinput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional uint32 canRxCounter = 11;
 * @return {number}
 */
proto.Status.prototype.getCanrxcounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setCanrxcounter = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 canTxOkCounter = 12;
 * @return {number}
 */
proto.Status.prototype.getCantxokcounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setCantxokcounter = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 canTxErrCounter = 13;
 * @return {number}
 */
proto.Status.prototype.getCantxerrcounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setCantxerrcounter = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 vehicleSpeed = 14;
 * @return {number}
 */
proto.Status.prototype.getVehiclespeed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setVehiclespeed = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool faultLightOn = 15;
 * @return {boolean}
 */
proto.Status.prototype.getFaultlighton = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setFaultlighton = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool absActive = 16;
 * @return {boolean}
 */
proto.Status.prototype.getAbsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setAbsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool absBrakeSwitchState = 17;
 * @return {boolean}
 */
proto.Status.prototype.getAbsbrakeswitchstate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setAbsbrakeswitchstate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int32 brakePressInput = 18;
 * @return {number}
 */
proto.Status.prototype.getBrakepressinput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setBrakepressinput = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional SelfTestResponse selfTest = 19;
 * @return {?proto.SelfTestResponse}
 */
proto.Status.prototype.getSelftest = function() {
  return /** @type{?proto.SelfTestResponse} */ (
    jspb.Message.getWrapperField(this, proto.SelfTestResponse, 19));
};


/**
 * @param {?proto.SelfTestResponse|undefined} value
 * @return {!proto.Status} returns this
*/
proto.Status.prototype.setSelftest = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearSelftest = function() {
  return this.setSelftest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasSelftest = function() {
  return jspb.Message.getField(this, 19) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Response.oneofGroups_ = [[10,11,12,13,14]];

/**
 * @enum {number}
 */
proto.Response.RespCase = {
  RESP_NOT_SET: 0,
  ACK: 10,
  NAK: 11,
  IDENT: 12,
  CONFIGURATION: 13,
  STATUS: 14
};

/**
 * @return {proto.Response.RespCase}
 */
proto.Response.prototype.getRespCase = function() {
  return /** @type {proto.Response.RespCase} */(jspb.Message.computeOneofCase(this, proto.Response.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    cmdid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ack: (f = msg.getAck()) && proto.Ack.toObject(includeInstance, f),
    nak: (f = msg.getNak()) && proto.Nak.toObject(includeInstance, f),
    ident: (f = msg.getIdent()) && proto.IdentResponse.toObject(includeInstance, f),
    configuration: (f = msg.getConfiguration()) && proto.WssConfig.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Response}
 */
proto.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Response;
  return proto.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Response}
 */
proto.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCmdid(value);
      break;
    case 10:
      var value = new proto.Ack;
      reader.readMessage(value,proto.Ack.deserializeBinaryFromReader);
      msg.setAck(value);
      break;
    case 11:
      var value = new proto.Nak;
      reader.readMessage(value,proto.Nak.deserializeBinaryFromReader);
      msg.setNak(value);
      break;
    case 12:
      var value = new proto.IdentResponse;
      reader.readMessage(value,proto.IdentResponse.deserializeBinaryFromReader);
      msg.setIdent(value);
      break;
    case 13:
      var value = new proto.WssConfig;
      reader.readMessage(value,proto.WssConfig.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 14:
      var value = new proto.Status;
      reader.readMessage(value,proto.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCmdid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAck();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.Ack.serializeBinaryToWriter
    );
  }
  f = message.getNak();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.Nak.serializeBinaryToWriter
    );
  }
  f = message.getIdent();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.IdentResponse.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.WssConfig.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 CmdId = 1;
 * @return {number}
 */
proto.Response.prototype.getCmdid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setCmdid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Ack ack = 10;
 * @return {?proto.Ack}
 */
proto.Response.prototype.getAck = function() {
  return /** @type{?proto.Ack} */ (
    jspb.Message.getWrapperField(this, proto.Ack, 10));
};


/**
 * @param {?proto.Ack|undefined} value
 * @return {!proto.Response} returns this
*/
proto.Response.prototype.setAck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearAck = function() {
  return this.setAck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasAck = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Nak nak = 11;
 * @return {?proto.Nak}
 */
proto.Response.prototype.getNak = function() {
  return /** @type{?proto.Nak} */ (
    jspb.Message.getWrapperField(this, proto.Nak, 11));
};


/**
 * @param {?proto.Nak|undefined} value
 * @return {!proto.Response} returns this
*/
proto.Response.prototype.setNak = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearNak = function() {
  return this.setNak(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasNak = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional IdentResponse ident = 12;
 * @return {?proto.IdentResponse}
 */
proto.Response.prototype.getIdent = function() {
  return /** @type{?proto.IdentResponse} */ (
    jspb.Message.getWrapperField(this, proto.IdentResponse, 12));
};


/**
 * @param {?proto.IdentResponse|undefined} value
 * @return {!proto.Response} returns this
*/
proto.Response.prototype.setIdent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearIdent = function() {
  return this.setIdent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasIdent = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional WssConfig configuration = 13;
 * @return {?proto.WssConfig}
 */
proto.Response.prototype.getConfiguration = function() {
  return /** @type{?proto.WssConfig} */ (
    jspb.Message.getWrapperField(this, proto.WssConfig, 13));
};


/**
 * @param {?proto.WssConfig|undefined} value
 * @return {!proto.Response} returns this
*/
proto.Response.prototype.setConfiguration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Status status = 14;
 * @return {?proto.Status}
 */
proto.Response.prototype.getStatus = function() {
  return /** @type{?proto.Status} */ (
    jspb.Message.getWrapperField(this, proto.Status, 14));
};


/**
 * @param {?proto.Status|undefined} value
 * @return {!proto.Response} returns this
*/
proto.Response.prototype.setStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * @enum {number}
 */
proto.AbsType = {
  MK60: 0,
  MK60E1: 1,
  MK60E5: 2,
  GENERICONOFF: 3
};

goog.object.extend(exports, proto);
